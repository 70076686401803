import { store } from '@/store';
import { ClientService } from '@shared/services/client';
import { createStorage } from '@shared/services/storage';
import { Logger as LOG } from '@shared/services/logger';
import { eventBus } from '@/utils/plugins/event-bus';
import { rAuthByCode, rAuthByRefreshToken } from '@shared/services/api/apis';
import { DevConfigs } from '@/utils/dev-config';

import { AUTH_EVENTS, authEmitter } from './events';
import { parseOauthResponse } from './utils';
import { logAuthError } from './utils/log-auth-error';

export class BaseAuthService {
  constructor(config) {
    this.config = config;

    console.log('config: ', config);

    this.redirectUrl = `${window.location.protocol}//${window.location.host}${config.redirectUrl}`;
    this.storage = createStorage(window.localStorage);
  }

  updateAuthContext(res) {
    this.storage.set('auth::token', res.accessToken);
    this.storage.set('auth::refresh-token', res.refreshToken);
    this.storage.set('auth::expire-in', res.expireIn);
    ClientService.updateContext(res);
  }

  onAuth() {
    eventBus.emit('eb_on_auth');
    return authEmitter.emit(AUTH_EVENTS.ON_AUTH);
  }

  logout() {
    LOG.event('AuthService logout');

    this.updateAuthContext({
      accessToken: '',
      refreshToken: '',
      expireIn: '',
    });
  }

  async authByCode(code, scope) {
    try {
      const payload = {
        redirectUri: this.redirectUrl,
        code,
      };
      if (scope) {
        payload.scope = scope;
      }

      ClientService.getFingerprint();

      const response = await rAuthByCode(payload, {
        path: this.config.authByCodeUrl,
      });
      const parsedToken = parseOauthResponse(response);
      this.updateAuthContext(parsedToken);

      return parsedToken;
    } catch (err) {
      console.log('AuthService authByCode error', err);
      throw err;
    }
  }

  async authByRefreshToken() {
    const { refreshToken } = ClientService.getContext();
    const token = refreshToken || this.storage.get('auth::refresh-token');

    if (!(token && token !== 'undefined')) {
      return Promise.reject(new Error('no refreshToken '));
    }
    ClientService.getFingerprint();

    try {
      const res = await rAuthByRefreshToken({ refreshToken: token });
      const parsedResponse = parseOauthResponse(res);
      this.updateAuthContext(parsedResponse);

      return res;
    } catch (err) {
      console.log('AuthService authByRefreshToken error', err);
      throw err;
    }
  }

  authReject() {
    this.logout();
    return Promise.reject(new Error('DefaultReject'));
  }

  shouldFetchTokens(ctx = {}, refreshToken) {
    return (
      ctx.code ||
      ctx.trackId ||
      ctx.isRefresh ||
      (refreshToken &&
        refreshToken !== 'null' &&
        refreshToken !== 'undefined') ||
      (DevConfigs.isDev && !DevConfigs.loginRefreshToken)
    );
  }

  fetchTokens(ctx = {}) {
    let { refreshToken } = ClientService.getContext();

    if (ctx.code) {
      LOG.event('Auth code', { ...ctx });
      return this.authByCode(ctx.code);
    }

    if (ctx.trackId) {
      LOG.event('Auth trackId', { ...ctx });
      return this.authByTrackId(ctx.trackId);
    }

    if (DevConfigs.isDev && !DevConfigs.loginRefreshToken) refreshToken = null;
    if (
      refreshToken &&
      refreshToken !== 'null' &&
      refreshToken !== 'undefined'
    ) {
      LOG.event('Auth refresh', { refreshToken });
      return this.authByRefreshToken();
    }

    if (ctx.isRefresh) {
      LOG.event('Auth reject refresh', { ...ctx });
      return this.authReject();
    }
  }

  handleAuthSuccess() {
    store.commit('SET_AUTH_STATE', true);
    this.onAuth();
  }

  handleAuthError(err) {
    LOG.event('Auth authorize reject', { err });
    store.commit('SET_AUTH_STATE', false);
    logAuthError(err);
    return this.authReject();
  }
}
