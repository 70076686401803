import { createStorage } from '@shared/services/storage';

const storage = createStorage(window.localStorage);

export const SamplingsModule = {
  namespaced: true,
  state: {
    samples: [],
    notAvailableSamplings: [],
    samplingPayload: null,
    activeSamplings: [],
    oldSamples: [],
    rejectedSampleIds: storage.getItem('rejectedSampleIds', []),
    enabledSamplingDelivery: storage.getItem('enabledSamplingDelivery', true),
  },
  getters: {
    samples(state) {
      return state.samples;
    },
    appliedSamples(state) {
      return state.samples?.filter(s => s.applied_count);
    },
    sampleProductsWithOutOfStock(state, getters, rootState, rootGetters) {
      const samples = [...state.samples];
      const cartPrice = rootGetters['cart/cartTotalPrice'];

      return samples
        .map(sample => {
          const product = {
            ...rootGetters['productDB/products'][
              `id_${sample.sample_product_id}`
            ],
          };

          let quantity = sample.applied_count;

          if (
            !product?.max_quantity ||
            product?.max_quantity < sample.applied_count
          ) {
            quantity = product?.max_quantity;
          }

          const sampleProduct = {
            ...product,
            quantity,
            sum_to_apply: sample.sum_to_apply,
            price: sample.sample_product_price || 1,
            sample_id: sample.id,
          };
          return sampleProduct;
        })
        .filter(p => {
          return p.sum_to_apply ? p.sum_to_apply <= cartPrice : true;
        });
    },
    sampleProducts(state, getters) {
      return getters.sampleProductsWithOutOfStock.filter(p => p.quantity > 0);
    },
    everyProductRejected(state, getters) {
      if (!getters.sampleProducts?.length) {
        return false;
      }

      return getters.sampleProducts.every(product =>
        state.rejectedSampleIds.includes(product.id)
      );
    },
    samplingTotalPrice(state, getters) {
      if (!state.enabledSamplingDelivery) {
        return 0;
      }
      const products = [...getters.sampleProducts]?.filter(
        product => !state.rejectedSampleIds.includes(product.id)
      );
      return products.reduce((acc, s) => acc + s.price * s.quantity, 0);
    },
    notAvailableSamplings(state) {
      return state.notAvailableSamplings;
    },
  },
  mutations: {
    SET_SAMPLES(state, value) {
      state.samples = value;
    },
    SET_NOT_AVAILABLE_SAMPINGS(state, value) {
      state.notAvailableSamplings = value;
    },
    SET_SAMPLING_PAYLOAD(state, value) {
      state.samplingPayload = value;
    },
    RESET_ALL(state) {
      state.samples = [];
      state.notAvailableSamplings = [];
    },
    SET_ACTIVE_SAMPLES(state, value) {
      state.activeSamplings = value;
    },
    RESET_ACTIVE_SAMPLES(state) {
      state.activeSamplings = [];
    },
    SET_OLD_SAMPLES(state, value) {
      state.oldSamples = value;
    },
    SET_REJECTED_SAMPLE_IDS(state, value) {
      state.rejectedSampleIds = value;
      storage.setItem('rejectedSampleIds', value);
    },
    SET_ENABLED_SAMPLING_DELIVERY(state, value) {
      state.enabledSamplingDelivery = value;
      storage.setItem('enabledSamplingDelivery', value);
    },
  },
};
