export const APP_PROVIDER_TYPES = {
  CHOCO_APP: 'CHOCO_APP',
  CHOCO_WEB: 'CHOCO_WEB',
  INDRIVE: 'INDRIVE',
};

export const APP_PROVIDER_CONFIGS = {
  [APP_PROVIDER_TYPES.CHOCO_APP]: {
    name: APP_PROVIDER_TYPES.CHOCO_APP,
    authCLientBaseUrl: import.meta.env.VITE_API_PROXY,
    clientBaseUrl: import.meta.env.VITE_API_PROXY,
    clientId: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
  },
  [APP_PROVIDER_TYPES.CHOCO_WEB]: {
    name: APP_PROVIDER_TYPES.CHOCO_WEB,
    authCLientBaseUrl: import.meta.env.VITE_API_PROXY,
    clientBaseUrl: import.meta.env.VITE_API_PROXY,
    clientId: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
  },
  [APP_PROVIDER_TYPES.INDRIVE]: {
    name: APP_PROVIDER_TYPES.INDRIVE,
    authCLientBaseUrl: import.meta.env.VITE_RYADOM_IDENTITY_GATEWAY,
    clientBaseUrl: import.meta.env.VITE_API_PROXY_RYADOM,
    clientId: import.meta.env.VITE_CLIENT_ID_INDRIVE,
  },
};
