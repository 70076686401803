import nativeBridge from '@shared/utils/native-bridge';
import { appDetection } from '@shared/services/app-detection';
import {
  APP_PROVIDER_TYPES,
  APP_PROVIDER_CONFIGS,
} from '@shared/services/app-provider/constants';

export let appProviderConfig = null;

const getAppProviderType = () => {
  if (appDetection.isIndrive) {
    return APP_PROVIDER_TYPES.INDRIVE;
  }

  if (appDetection.isChoco) {
    return nativeBridge.isWebView()
      ? APP_PROVIDER_TYPES.CHOCO_APP
      : APP_PROVIDER_TYPES.CHOCO_WEB;
  }

  return APP_PROVIDER_TYPES.CHOCO_WEB;
};

export const setAppProviderConfig = () => {
  const type = getAppProviderType();

  appProviderConfig = APP_PROVIDER_CONFIGS[type];
};

setAppProviderConfig();
