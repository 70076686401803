import { createStorage } from '@shared/services/storage';
import { parseToken } from '@shared/utils/parse-token';
import nativeBridge from '@shared/utils/native-bridge';
import { v4 as uuidv4 } from 'uuid';

const storage = createStorage(window.localStorage);

function getInitRefresh() {
  if (nativeBridge.isWebView()) return undefined;
  return storage.get('auth::refresh-token')
    ? storage.get('auth::refresh-token')
    : undefined;
}

const clientContext = {
  deviceId: undefined,
  sessionId: undefined,
  platform: undefined,
  fingerprint: '',
  amplitudeSessionId: undefined,
  v: undefined,

  accessToken: storage.get('auth::token'),
  refreshToken: getInitRefresh(),
  expireIn: storage.get('auth::expire-in'),
};

const updateContext = newContext => {
  Object.assign(clientContext, newContext);
};
const getContext = () => {
  return clientContext;
};

function getFingerprint() {
  let { fingerprint } = getContext();
  if (fingerprint) fingerprint;
  fingerprint = `${uuidv4()}`;
  updateContext({ fingerprint });
  return fingerprint;
}

const getUserId = () => {
  let userId = -1;
  let jwt = null;
  const { accessToken } = getContext();
  if (accessToken) jwt = parseToken(accessToken);
  if (jwt?.sub) userId = jwt.sub;
  return userId;
};

export const ClientService = {
  getFingerprint,
  updateContext,
  getContext,
  getUserId,
};
