import { store } from '@/store';
import {
  rFetchSamplings,
  rFetchActiveSamplings,
} from '@shared/services/api/apis';
import { Logger as LOG } from '@shared/services/logger';
import { CartService } from '@services/cart';

import { getObjectDifferences } from '@/utils/objects/object-differences';
import { arrayDifference } from '@/utils/arrays/array-deifference';
import { ProductBDService } from '@services/product-db';
import { eventBus } from '@/utils/plugins/event-bus';
import { CartEmitter, CART_EVENTS } from '@services/cart/events';

/**
 * @typedef AdditionalProduct Продукт который нужно добрать для семплинга
 * @prop {Number} product_id айди товара
 * @prop {Number} quantity количество товара которое нужно добрать
 *
 *
 * @typedef Sample
 * @prop {Number} id
 * @prop {String} type product | category | any
 * @prop {Number} sample_product_id айди семплового товара
 * @prop {Number?} sample_price цена семплового товара
 * @prop {Number} applied_count сколько семплов добавлено
 * @prop {Number} sum_to_apply мин чек для применения семпла
 * @prop {null | AdditionalProduct[]} getting_to_apply Сколько нужно добрать для семпла
 */

let lastWarehouseId = 1;

const getWarehouseId = () => {
  return store.getters['delivery/warehouseId'];
};
const getCartProducts = () => {
  return [...store.getters['cart/items']];
};
const getAddedProduct = id => {
  if (!id) return null;
  const cart = getCartProducts();
  const product = cart.find(item => item.product_id === id);
  return { ...product } || null;
};
const setSamples = value => {
  store.commit('samplings/SET_SAMPLES', value);
};
const setActiveSamplings = value => {
  store.commit('samplings/SET_ACTIVE_SAMPLES', value);
};
const resetSamlpes = () => {
  store.commit('samplings/SET_SAMPLES', []);
};
const resetActiveSamplings = () => {
  store.commit('samplings/SET_ACTIVE_SAMPLES', []);
};
const getSamples = () => {
  return [...store.getters['samplings/samples']];
};
// const getActiveSamplings = () => {
//   return [...store.getters['samplings/activeSamplings']];
// };

const setSamplingPayload = value => {
  store.commit('samplings/SET_SAMPLING_PAYLOAD', value);
};
const getSamplingPayload = () => store.state.samplings.samplingPayload;

const setOldSamples = value => {
  store.commit('samplings/SET_OLD_SAMPLES', value);
};
const getOldSamples = () => {
  return [...(store.state.samplings.oldSamples || [])];
};

const getRejectedSampleIds = () => {
  return [...(store.state.samplings.rejectedSampleIds || [])];
};
const setRejectedSampleIds = value => {
  store.commit('samplings/SET_REJECTED_SAMPLE_IDS', value);
};
const addRejectedSampleId = id => {
  const rejectedSampleIds = getRejectedSampleIds();
  if (rejectedSampleIds?.some(item => item === id)) {
    return;
  }
  store.commit('samplings/SET_REJECTED_SAMPLE_IDS', [
    ...getRejectedSampleIds(),
    id,
  ]);
};
const removeRejectedSampleId = id => {
  store.commit(
    'samplings/SET_REJECTED_SAMPLE_IDS',
    getRejectedSampleIds().filter(item => item.id !== id)
  );
};

const resetDisabledSamplings = () => {
  setRejectedSampleIds([]);
  store.commit('samplings/SET_ENABLED_SAMPLING_DELIVERY', true);
};

const resetAll = () => {
  store.commit('samplings/RESET_ALL');
};

function openModal(data, options = {}) {
  eventBus.emit('eb_open_samplings_modal', { ...data, options });
}

/**
 *  Выносим все айдишки товаров из обеъкта Sample, это семплы и это доп товары для добора
 * @param {Sample[]} samples массив семплов
 * @returns {Number[]} айди товаров
 */
function extractProductIds(samples) {
  const ids = [];

  samples.forEach(sample => {
    ids.push(sample.sample_product_id);

    const gettings = sample.getting_to_apply;
    if (gettings && gettings.length) {
      gettings.forEach(product => ids.push(product.product_id));
    }
  });
  return ids;
}

/**
 * Тут мы получаем все инфу товаров по айдишникам которые были в объекте семплов
 * и сохраняем их в store ProductDBModule для кеширования
 * @param {Sample[]} samples семплы
 * @returns
 */
async function loadProductsInfo(samples) {
  let ids = extractProductIds(samples);
  LOG.event('Samplings', { samples, ids }, 'loadProductsInfo');
  await ProductBDService.loadProductsByIds(ids);
}

async function loadActiveProductsInfo(products) {
  const warehouseId = getWarehouseId();
  return await ProductBDService.loadProductsByIds(products, warehouseId);
}

/**
 *
 * @param {Sample[]} oldSamples
 * @param {Sample[]} newSamples
 * @returns
 */
export function checkDifferences(oldSamples, newSamples) {
  console.log('checkDifferences oldSamples: ', oldSamples);
  console.log('checkDifferences newSamples: ', newSamples);
  let hasChanges = false;
  const added = [];
  const removed = [];
  const changed = [];

  oldSamples = JSON.parse(
    JSON.stringify(oldSamples && oldSamples.length ? oldSamples : [])
  );
  newSamples = newSamples && newSamples.length ? newSamples : [];
  console.log('checkDifferences oldSamples: ', oldSamples);
  console.log('checkDifferences newSamples: ', newSamples);

  const oldIds = oldSamples.map(e => e.id);
  const newIds = newSamples.map(e => e.id);
  console.log('oldIds:', oldIds);
  console.log('newIds:', newIds);
  const diffIds = arrayDifference(oldIds, newIds);

  console.log('diffIds:', diffIds);

  newSamples.forEach(ns => {
    const oldSample = oldSamples.find(os => os.id === ns.id);
    if (oldSample) {
      console.log('forEach oldSample:', oldSample);
      const diffs = getObjectDifferences(oldSample, ns);
      console.log('forEach diffs:', diffs);
      if (Object.keys(diffs).length) changed.push({ ...ns, diffs });
    }
  });

  console.log('newSamples:', newSamples);

  if (diffIds.length) {
    newSamples.forEach(sample => {
      console.log('add added array');
      if (diffIds.includes(sample.id)) added.push(sample);
    });
    oldSamples.forEach(sample => {
      if (diffIds.includes(sample.id)) removed.push(sample);
    });
  }
  console.log('newSamples 2:', newSamples);
  console.log('oldSamples:', oldSamples);

  hasChanges = !!(added.length || removed.length || changed.length);
  console.log('added:', added);
  console.log('changed:', changed);
  console.log('removed:', removed);
  console.log('hasChanges:', hasChanges);

  return { hasChanges, samples: newSamples, added, removed, changed };
}

const getAdditionalProducts = (products, originalProducts) => {
  if (!products || !products.length) return [];
  if (originalProducts?.length === 1 && originalProducts?.[0]?.quantity === 1) {
    return [];
  }

  return products.map(product => {
    return {
      ...ProductBDService.getProductByKey(product.product_id),
      quantity: product.quantity,
    };
  });
};

function buildPayloadForPresentation(formatedSamples) {
  if (!formatedSamples?.samples?.length) {
    setSamplingPayload(null);
    return;
  }

  let sample = null;
  const added = formatedSamples.added[0];
  const changed = formatedSamples.changed[0];
  console.log('added: ', added);
  console.log('changed: ', changed);

  if (added) {
    sample = { ...added, triggeredType: 'added' };

    if (!sample?.sample_product?.quantity) {
      sample.isOutOfStock = true;
    }
  } else if (changed?.diffs?.applied_count && changed?.diffs.applied_count[0]) {
    sample = { ...changed, triggeredType: 'reached' };

    if (changed?.diffs?.applied_count[1] <= changed?.diffs?.applied_count[0]) {
      setSamplingPayload(null);
      return;
    }

    if (
      changed?.diffs?.sample_product?.[0]?.quantity &&
      !changed?.diffs?.sample_product?.[0]?.quantity
    ) {
      sample.isOutOfStock = true;
    }
  } else sample = { ...changed, triggeredType: 'changed' };

  const sampling = ProductBDService.getProductByKey(sample.sample_product_id);
  sampling.price = sample.sample_product_price || 1;
  sampling.quantity = sample.applied_count;

  const addedProducts = sample.products_to_apply?.map(product => {
    return getAddedProduct(product.id);
  });

  const additionalProducts = getAdditionalProducts(
    sample.getting_to_apply?.filter(p => !p.cart_sum),
    sample.products_to_apply
  );

  setSamplingPayload({
    sample,
    sampling,
    addedProducts,
    additionalProducts,
  });
}
function filterUnAppliedSamples(samples) {
  if (!samples || !samples.length) return [];
  return samples.filter(s => {
    if (s.getting_to_apply?.[0]?.cart_sum) {
      return false;
    }

    if (s.sample_product?.quantity === 0) return true;

    return !(s.getting_to_apply === null && s.applied_count === 0);
  });
}

async function formatSampleRequest(newSamples, options = {}) {
  console.log('foo: ', getSamples(), store.state.samplings.samples);
  console.log('bar: ', getOldSamples());
  const oldSamples = JSON.parse(
    JSON.stringify(getSamples()?.length ? getSamples() : getOldSamples())
  );
  oldSamples.map(s => delete s.diffs);
  newSamples = filterUnAppliedSamples(newSamples);
  console.log('samplings oldSamples: ', oldSamples);
  console.log('samplings newSamples: ', newSamples);
  const formatedSamples = checkDifferences(oldSamples, newSamples);

  setSamples(formatedSamples.samples);
  LOG.event('formatSampleRequest', formatedSamples, 'SamplingsService');
  const changes = [...formatedSamples.changed, ...formatedSamples.added];

  if (formatedSamples.removed?.length) {
    formatedSamples.removed.forEach(sample =>
      removeRejectedSampleId(sample.id)
    );
  }

  if (changes.length) {
    await loadProductsInfo(changes);

    buildPayloadForPresentation(formatedSamples);

    const payload = getSamplingPayload();
    if (!payload) return Promise.resolve();

    if (
      changes?.filter(c => c.applied_count)?.length ||
      payload.sample.isOutOfStock
    ) {
      LOG.event('OpenModal', payload, 'SamplingsService');
      openModal(payload, options);
    }
  }
  return Promise.resolve();
}

function formatRequestProducts(products, key = 'product_id') {
  return products.map(item => {
    return {
      product_id: +item[key],
      feature_id: item.feature_id || null,
      quantity: +item.quantity,
    };
  });
}

function resetIfWarehouseChanged() {
  const warehouseId = getWarehouseId();
  const isWarehouseChanged = lastWarehouseId !== warehouseId;
  LOG.event('resetIfWarehouseChanged', { isWarehouseChanged }, 'Samplings');
  if (isWarehouseChanged) {
    setOldSamples(
      JSON.parse(JSON.stringify([...store.state.samplings.samples]))
    );

    resetAll();
  }

  lastWarehouseId = warehouseId;
}

function loadSamplings(options = {}) {
  const warehouseId = getWarehouseId();
  resetIfWarehouseChanged();

  const products = formatRequestProducts(getCartProducts());
  if (!products || !products.length) {
    resetSamlpes();
    return Promise.resolve([]);
  }

  return new Promise(resolve => {
    rFetchSamplings(products, warehouseId)
      .then(res => res.data)
      .then(res => formatSampleRequest(res, options))
      .then(res => resolve(res))
      .catch(err => {
        LOG.event('LoadSamplings Err', err, 'Samplings');
        console.error(err);
        resolve([]);
      });
  });
}

const loadActiveSamplings = async () => {
  try {
    const warehouseId = getWarehouseId();
    resetIfWarehouseChanged();

    const res = await rFetchActiveSamplings({ warehouse_id: warehouseId });
    let data = res.data;

    const arr = data?.reduce((acc, curr) => {
      acc.push(curr.product?.id);
      if (curr.type !== 'category') {
        curr.rules?.forEach(rule => {
          acc.push(rule.condition_element_id);
        });
      }
      return acc;
    }, []);

    const prodInfo = await loadActiveProductsInfo(arr);

    data = data.map(item => {
      if (item.product) {
        item.product.max_quantity = prodInfo.find(
          product => product.id === item.product?.id
        )?.max_quantity;

        item.product.path = item.product?.paths[0]?.path;
      }

      if (item.rules) {
        item.rules = item.rules.map(rule => {
          rule.condition_element = prodInfo.find(
            product => product.id === rule.condition_element_id
          );
          if (rule.condition_element) {
            rule.condition_element.quantity = rule.required_quantity;
          }
          return rule;
        });
      }

      return item;
    });

    setActiveSamplings(data);

    return data;
    // return res.data;
  } catch (err) {
    LOG.event('LoadActiveSamplings Err', err, 'Samplings');
    console.error(err);
    return [];
  }
};

/**
 * Добваляем в корзину все
 * @param {Product[]} products
 * @returns
 */
function addAdditionalProducts(items) {
  const cartProducts = formatRequestProducts(getCartProducts());
  let products = items.filter(p => p.max_quantity > 0);
  const additionalProducts = formatRequestProducts(products, 'id');
  products = [...cartProducts, ...additionalProducts];

  const isAuthorized = store.state.isAuthorized;

  if (isAuthorized) {
    return CartService.applyProducts(products)
      .then(() => loadSamplings({ toast: true }))
      .catch(err => {
        console.error(err);
      });
  }

  const promises = [];
  items
    .map(product => ({ ...product, productId: product.id }))
    .forEach(product => store.dispatch('cart/ADD_TO_CART', product));

  return Promise.all(promises);
}

function init() {
  loadActiveSamplings();
  eventBus.on('eb_on_reconnect_to_app', loadActiveSamplings);
  eventBus.on('eb_on_address_change', loadActiveSamplings);
  eventBus.on('eb_on_address_update', loadActiveSamplings);
  CartEmitter.on(CART_EVENTS.ON_APPLY_PROMOCODE, loadSamplings);
  CartEmitter.on(CART_EVENTS.ON_CART_CHANGED, loadSamplings);
  CartEmitter.on(CART_EVENTS.ON_CART_FETCHED, loadSamplings);
  CartEmitter.on(CART_EVENTS.ON_PAYMENT_DONE, resetDisabledSamplings);
}

export const SamplingsService = {
  init,
  addAdditionalProducts,
  loadSamplings,
  loadActiveSamplings,
  resetSamlpes,
  resetActiveSamplings,
  openModal,
  getRejectedSampleIds,
  setRejectedSampleIds,
  addRejectedSampleId,
  removeRejectedSampleId,
};
