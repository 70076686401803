import { OpenIdConnect } from '@shared/services/auth/utils/auth-iframe';
import { getQueriesFromUrl } from '@shared/utils';

export class WebAuthService {
  constructor(authConfig) {
    this.loading = false;

    this.checkSessionURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/check_session`;
    this.loginURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/login`;
    this.logoutURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/logout`;
    console.log('this.redirectUrl authConfig 3: ', authConfig);
    console.log(
      'this.redirectUrl authConfig.redirectUrl: ',
      authConfig.redirectUrl
    );
    this.redirectUrl = `${window.location.protocol}//${window.location.host}${authConfig.redirectUrl}`;
  }

  buildOauthUrl(params = {}) {
    const oauthUrl = new URL(this.loginURL);

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        oauthUrl.searchParams.append(key, value);
      }
    }

    return oauthUrl.href;
  }

  login(route = null) {
    const queries = getQueriesFromUrl();

    let redirectUrl = window.location.href.split('?')[0];
    redirectUrl = route ?? new URL(redirectUrl).pathname;

    console.log('this.redirectUrl: ', this.redirectUrl);

    const state = btoa(JSON.stringify({ ...queries, route: redirectUrl }));
    const url = this.buildOauthUrl({
      client_id: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
      redirect_uri: this.redirectUrl,
      'login-by-mail': false,
      state,
    });

    window.location.href = url;
  }

  checkOauth({ parseResponse = this.defaultParseResponse.bind(this) } = {}) {
    if (this.loading) return;
    console.log('this.login: ', this.login);

    return new Promise(resolve => {
      this.loading = true;

      const op = new OpenIdConnect(this.checkSessionURL);
      op.sendMessage('OpenIdConnected');

      op.listen(e => {
        const result = parseResponse(e);
        if (result !== undefined) {
          this.loading = false;
          resolve(result);
        }
      });
    });
  }

  logout() {
    const queries = getQueriesFromUrl();

    const oauthLogout = new URL(this.logoutURL);
    oauthLogout.searchParams.append(
      'state',
      btoa(JSON.stringify({ ...queries, route: '/auth', isLogout: true }))
    );

    window.location.href = oauthLogout.href;
  }

  defaultParseResponse(e) {
    console.log('this.login 2: ', this.login);
    if (e.data === 'auth') {
      this.login?.();
    }
    if (e.data === 'no_auth') {
      return 'no_auth';
    }
  }
}
