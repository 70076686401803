const createIframe = (source, id) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', source);
  iframe.setAttribute('id', id);
  iframe.style.width = String(0);
  iframe.style.height = String(0);
  iframe.style.border = String(0);
  iframe.title = 'eco-system';
  iframe.style.position = 'absolute';
  return iframe;
};

const sendMessage = (source, id) => {
  return msg => {
    const iframeBlock = document.getElementById(id);
    iframeBlock.onload = () => {
      iframeBlock?.contentWindow?.postMessage(msg, source);
    };
  };
};

function listen(cb) {
  window.addEventListener('message', cb, false);
}

/**
 * Подключемся по урлу к сервису чоко для проверки авторизаций.
 *
 * Этот сервис общий для всех продуктов Чоко
 *
 * Создает айфрейм лоудит сайт в айфрейме и слушает ответ от сервера
 * @param {String} source это урл сервиса чоко
 */
export function OpenIdConnect(source) {
  const self = this;
  self.iframeId = 'iframeOP';
  self.source = source;
  self.listen = listen;

  document.body.appendChild(createIframe(self.source, self.iframeId));

  self.sendMessage = sendMessage(self.source, self.iframeId);
}
