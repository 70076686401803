import { authClient } from '../../clients/auth-client';
import { appProviderConfig } from '@shared/services/app-provider';

export default function (ctx, { path }) {
  const body = {
    client_id: appProviderConfig.clientId,
    grant_type: 'authorization_code',
    redirect_uri: ctx.redirectUri,
    code: ctx.code,
  };

  if (ctx.scope) {
    body.scope = ctx.scope;
  }

  const params = {
    useFingerprint: true,
    useIdempotency: true,
    headers: {
      'Content-Type': 'application/json',
    },
    paramsSerializer: v => JSON.stringify(v),
    transformRequest: v => JSON.stringify(v),
  };
  console.log('path: ', path);
  return authClient.post(path, body, params).then(({ data }) => data);
}
