import { APP_PROVIDER_TYPES } from '@shared/services/app-provider/constants';

import { ChocoAppAuthService } from '@shared/services/auth/auth-choco-app';
import { ChocoWebAuthService } from '@shared/services/auth/auth-choco-web';
import { IndriveAuthService } from '@shared/services/auth/auth-indrive';

export const AUTH_URLS = [
  '/auth/track',
  '/auth/token',
  '/api/v1/oauth2/tracks',
  '/api/v1/oauth2/tokens',
  '/api/v2/oauth2/tokens',
  '/api/v2/oauth2/tokens',
];

export const AUTH_CONFIGS = {
  [APP_PROVIDER_TYPES.CHOCO_APP]: {
    authState: 'choco',
    authService: ChocoAppAuthService,
    authByCodeUrl: 'api/v2/oauth2/tokens',
    redirectUrl: '/auth',
  },
  [APP_PROVIDER_TYPES.CHOCO_WEB]: {
    authState: 'choco',
    authService: ChocoWebAuthService,
    authByCodeUrl: 'api/v2/oauth2/tokens',
    redirectUrl: '/auth',
  },
  [APP_PROVIDER_TYPES.INDRIVE]: {
    authState: 'indrive',
    authService: IndriveAuthService,
    authByCodeUrl: 'oauth/tokens',
    redirectUrl: '/callback',
  },
};
