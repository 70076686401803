import { BaseAuthService } from './auth-base';
import { ClientService } from '@shared/services/client';
import { Logger as LOG } from '@shared/services/logger';
import { WebAuthIdentityService } from './web-auth-identity-service';

export class IndriveAuthService extends BaseAuthService {
  constructor(config) {
    super(config);

    console.log('config 0: ', config);
    this.webAuthService = new WebAuthIdentityService(config);
  }

  async auth(ctx = {}) {
    let { accessToken } = ClientService.getContext();
    LOG.event('AuthService.authorize start auth-indrive', ctx);

    console.log('AuthService ctx.code: ', ctx.code);
    if (ctx.code) {
      LOG.event('Auth code', { ...ctx });
      return this.authByCode(ctx.code);
    }

    console.log('accessToken: ', accessToken);

    if (accessToken && !ctx.authError) {
      LOG.event('Auth has tokens', { accessToken });
      return Promise.resolve();
    }

    console.log('AuthService 2');

    if (!ctx.isInit || ctx.authError) {
      LOG.event('Auth checkOauth', { ...ctx });
      try {
        const res = await this.webAuthService.checkOauth();
        LOG.event('Auth login Oauth', { res });

        if (res?.result === 'auth') {
          const response = res?.response;
          console.log('Iframe res success: ', response);

          if (response?.accessToken) {
            this.updateAuthContext(res.response);

            return Promise.resolve();
          }

          return this.authReject();
        } else {
          return this.authReject();
        }
      } catch (err) {
        console.log('Auth checkOauth error', err);
        throw err;
      }
    }
    console.log('AuthService 3');

    this.webAuthService.login();
    return new Promise(() => {});
  }

  async authorize(ctx) {
    try {
      await this.auth(ctx);
      console.log('AUTH result success');
      this.handleAuthSuccess();
    } catch (err) {
      console.log('AUTH result error');
      LOG.event('Auth authorize reject', { err });
      return this.handleAuthError(err);
    }
  }

  updateAuthContext(res) {
    this.storage.set('auth::token', res.accessToken);
    this.storage.set('auth::expire-in', res.expireIn);
    ClientService.updateContext(res);
  }
}
