import { BaseAuthService } from './auth-base';
import { DevConfigs } from '@/utils/dev-config';
import { ClientService } from '@shared/services/client';
import { Logger as LOG } from '@shared/services/logger';
import { WebAuthService } from './web-auth-service';

export class ChocoWebAuthService extends BaseAuthService {
  constructor(config) {
    super(config);

    this.webAuthService = new WebAuthService(config);
  }

  logoutWeb() {
    super.logout();

    this.webAuthService.logout();
  }

  async auth(ctx = {}) {
    let { refreshToken } = ClientService.getContext();
    LOG.event('AuthService.authorize start choco web', {
      ...ctx,
      refreshToken,
    });

    if (DevConfigs.isDev && !DevConfigs.login) {
      LOG.event('Auth reject isDev', ctx);
      return this.authReject();
    }

    if (this.shouldFetchTokens(ctx, refreshToken)) {
      return this.fetchTokens(ctx);
    }

    if (ctx.isInit) {
      LOG.event('Auth checkOauth', { ...ctx });
      return this.webAuthService.checkOauth().then(res => {
        LOG.event('Auth checkOauth', { res });
        if (res === 'auth') return WebAuthService.login(ctx.path);
        return this.authReject();
      });
    }

    LOG.event('Auth desktop', { ...ctx });
    this.webAuthService.login(ctx.path);
    return this.authReject();
  }

  async authorize(ctx) {
    try {
      await this.auth(ctx);
      this.handleAuthSuccess();
    } catch (err) {
      LOG.event('Auth authorize reject', { err });
      return this.handleAuthError(err);
    }
  }
}
