import { AUTH_CONFIGS } from '@shared/services/auth/constants';
import { appProviderConfig } from '@shared/services/app-provider';

export { default as parseOauthStateRoute } from './parse-oauth-state-route';
export { default as getRedirectUri } from './get-redirect-uri';

export const parseOauthResponse = res => {
  return {
    accessToken: res.access_token,
    refreshToken: res.refresh_token,
    expireIn: res.expires_in,
  };
};

export const getAuthConfig = () => {
  return AUTH_CONFIGS[appProviderConfig.name];
};
