const USER_AGENT_KEYS = {
  RYADOM: 'chocosuperapp',
  INDRIVE: 'indrive',
};

class AppDetectionService {
  get userAgent() {
    return navigator.userAgent.toLowerCase();
  }

  get isChoco() {
    return this.userAgent.includes(USER_AGENT_KEYS.RYADOM.toLowerCase());
  }

  get isIndrive() {
    return this.userAgent.includes(USER_AGENT_KEYS.INDRIVE.toLowerCase());
  }
}

export const appDetection = new AppDetectionService();
