import { WebAuthService } from '@shared/services/auth/web-auth-service.js';

export class WebAuthIdentityService extends WebAuthService {
  constructor(authConfig) {
    super(authConfig);

    console.log('authConfig 2: ', authConfig);
    this.loginURL = `${import.meta.env.VITE_RYADOM_IDENTITY_GATEWAY}/oauth/${authConfig.authState}/login`;
    this.redirectUrl = `${window.location.protocol}//${window.location.host}${authConfig.redirectUrl}`;
    this.checkSessionURL = this.redirectUrl;
    this.authState = authConfig?.authState;
  }

  login() {
    if (this.loading) return;

    this.loading = true;

    const url = this.buildOauthUrl({
      client_id: import.meta.env.VITE_CLIENT_ID_INDRIVE,
      response_type: 'code',
      redirect_uri: this.redirectUrl,
      state: this.authState,
      scope: 'ryadom_customer',
    });

    window.location.href = url;
    return new Promise(() => {});
  }

  checkOauth() {
    return super.checkOauth({
      parseResponse: e => {
        if (e.data?.result === 'auth') {
          return e.data;
        }
        if (e.data?.result === 'no_auth') {
          return 'no_auth';
        }
      },
    });
  }
}
