import axios from 'axios';
import { stringify } from 'qs';
import { Logger as LOG } from '@shared/services/logger';
import {
  injectCity,
  injectLang,
  injectToken,
  isAuthError,
  injectDeviceId,
  transformError,
  injectFingerprints,
  injectIdempotencyKey,
  handleUnauthorizedResponse,
} from './utils';

import { installAxiosMeasureTime } from './utils/install-axios-measure-time';
import { appProviderConfig } from '@shared/services/app-provider';

const lavkaFavoritesClient = axios.create({
  baseURL: appProviderConfig.clientBaseUrl,
  paramsSerializer: stringify,
  transformRequest: [data => JSON.stringify(data)],
  headers: {
    'Content-Type': 'application/json',
  },
});

installAxiosMeasureTime(lavkaFavoritesClient);

lavkaFavoritesClient.interceptors.request.use(
  config => {
    injectCity(config);
    injectLang(config);
    injectToken(config);
    injectDeviceId(config);
    injectFingerprints(config);
    injectIdempotencyKey(config);
    LOG.event(`Config ${config.url}`, config, 'Network');
    return config;
  },
  err => Promise.reject(err)
);

lavkaFavoritesClient.interceptors.response.use(
  res => {
    LOG.event(`Res: ${res.config.url}`, res.data, 'Network');

    if (isAuthError(res.data))
      return handleUnauthorizedResponse(res).then(config =>
        lavkaFavoritesClient(config)
      );

    return res.data.error_code && res.data.error_code !== 0
      ? Promise.reject(transformError(res.data))
      : res;
  },
  err => {
    LOG.event(`Err: ${err.config.url}`, err.data, 'Network');

    if (isAuthError(err))
      return handleUnauthorizedResponse(err).then(config =>
        lavkaFavoritesClient(config)
      );

    return Promise.reject(transformError(err));
  }
);

export { lavkaFavoritesClient };
