import * as Sentry from '@sentry/vue';
import OutgoingEventsHandler from './helpers/OutgoingEventsHandler';
import {
  HAPTIC_TYPES_INDRIVE,
  OUTGOING_EVENTS,
  OUTGOING_EVENTS_INDRIVE,
} from './constants';

const SENTRY_CONTEXT = 'Indrive super app';
const SENTRY_EXTRA = { message: 'Native app methods' };

class Indrive {
  constructor() {
    this.eventHandler = new OutgoingEventsHandler();
  }

  requestGeoPermissionStatus() {
    return new Promise(resolve => {
      this.registerOutgoingEvent(OUTGOING_EVENTS.ON_GEO_DEFINED, payload => {
        resolve(payload?.status);
      });
      this.postMessage('request_geo_permission_status');
    });
  }

  // TODO: implement it when marketing is ready
  logAppsflyerEvent() {}

  backToNativeApp() {
    return this.postMessage('dismiss');
  }

  hapticSelection() {
    return this.postMessage('play_haptic', { type: 'selection' });
  }

  hapticNotification(type) {
    return this.postMessage('play_haptic', {
      type: HAPTIC_TYPES_INDRIVE[type],
    });
  }

  hapticImpact(type) {
    return this.postMessage('play_haptic', {
      type: HAPTIC_TYPES_INDRIVE[type],
    });
  }

  openLink(body) {
    return this.postMessage('open_external_url', { url: body?.url });
  }

  hideNavigationBar() {
    return this.postMessage('hide_navigation_bar');
  }

  openAppSettings() {
    return this.postMessage('open_app_settings');
  }

  reloadPage() {
    return this.postMessage('reload_page');
  }

  requestNotificationStatus() {
    return new Promise(resolve => {
      this.registerOutgoingEvent(
        OUTGOING_EVENTS.ON_NOTIFICATION_DEFINED,
        payload => {
          resolve(payload?.status);
        }
      );
      this.postMessage('request_notification_status');
    });
  }

  openAppSettingsAwaitNotificationStatus() {
    return new Promise(resolve => {
      const eventHandler = async () => {
        this.cancelOutgoingEvent(OUTGOING_EVENTS.ON_APPLICATION_ACTIVE);
        const status = await this.requestNotificationStatus();
        resolve(status);
      };

      this.registerOutgoingEvent(
        OUTGOING_EVENTS.ON_APPLICATION_ACTIVE,
        eventHandler
      );
      this.postMessage('open_app_settings');
    });
  }

  pay(order_id) {
    return this.postMessage('request_payment', { order_id });
  }

  applicationBecomeActive(callback) {
    this.registerOutgoingEvent(OUTGOING_EVENTS.ON_APPLICATION_ACTIVE, callback);
  }

  // TODO: implement it when indrive is ready
  hasMethod() {}

  registerOutgoingEvent(methodName, callback) {
    this.eventHandler.register(methodName, callback);
  }

  cancelOutgoingEvent(methodName) {
    this.eventHandler.cancel(methodName);
  }

  setupWebViewApi() {
    window.callWebView = data => {
      this.eventHandler.handleEvent(
        OUTGOING_EVENTS_INDRIVE[data?.action],
        data?.payload
      );
    };
  }

  postMessage(action, payload) {
    const message = { action, ...(payload ? { data: payload } : {}) };

    try {
      if (this.postToIOS(message)) return;
      if (this.postToAndroid(message)) return;

      Sentry.captureException(`[${SENTRY_CONTEXT}] No handler found`, {
        extra: { ...SENTRY_EXTRA, action, payload },
      });
    } catch (error) {
      Sentry.captureException(`[${SENTRY_CONTEXT}] Error calling: ${action}`, {
        extra: { ...SENTRY_EXTRA, action, payload, error },
      });
    }
  }

  postToIOS(message) {
    const iOSHandler = window?.webkit?.messageHandlers?.inDriveriOSHandler;
    if (typeof iOSHandler?.postMessage === 'function') {
      iOSHandler.postMessage(message);
      return true;
    }
    return false;
  }

  postToAndroid(message) {
    const androidHandler = window?.inDrive;
    if (typeof androidHandler?.postMessage === 'function') {
      androidHandler.postMessage(JSON.stringify(message));
      return true;
    }
    return false;
  }
}

export default Indrive;
