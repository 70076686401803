import nativeBridge from '@shared/utils/native-bridge';
import { getRedirectUri } from './utils';

function authorizeV1() {
  return nativeBridge.authorize().then(res => {
    return { trackId: res };
  });
}

function authorizeV2(redirectUrl) {
  const body = {
    client_id: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.host}${redirectUrl}`,
    response_type: 'code',
    state: getRedirectUri(),
  };
  console.log('body: ', body);
  return nativeBridge.authorizeV2(body).then(res => {
    return { code: res.code };
  });
}

const login = ({ redirectUrl }) => {
  console.log('nativeBridge.authVersion: ', nativeBridge.authVersion);
  console.log('nativeBridge.authVersion(): ', nativeBridge.authVersion());
  const isV2 = nativeBridge.authVersion() === 'v2';
  return isV2 ? authorizeV2(redirectUrl) : authorizeV1();
};

export const MobAuthService = {
  login,
};
