import { BaseAuthService } from './auth-base';
import nativeBridge from '@shared/utils/native-bridge';
import { DevConfigs } from '@/utils/dev-config';
import { ClientService } from '@shared/services/client';
import { Logger as LOG } from '@shared/services/logger';
import { MobAuthService } from './mobile-auth-service';
import { rGetTokenByTrackId } from '@shared/services/api/apis';

import { parseOauthResponse } from './utils';

export class ChocoAppAuthService extends BaseAuthService {
  constructor(config) {
    super(config);
  }

  isMiniAppWithCheckAuth() {
    return nativeBridge.isWebView() && nativeBridge.hasMethod('checkAuth');
  }

  async authByTrackId(trackId) {
    try {
      const res = await rGetTokenByTrackId(trackId);

      const parsedResponse = parseOauthResponse(res);
      if (parsedResponse?.access_token) {
        this.updateAuthContext(parsedResponse);
      }
    } catch (err) {
      console.log('AuthService authByTrackId error', err);
      throw err;
    }
  }

  async authInMiniApp() {
    try {
      const res = await MobAuthService.login({
        redirectUrl: this.config.redirectUrl,
      });
      LOG.event('Auth MobAuthService ', res);

      if (res.trackId && res.trackId !== 'trackID') {
        return this.authByTrackId(res.trackId);
      }
      if (res.code && res.code !== 'code') {
        return this.authByCode(res.code);
      }
      return Promise.reject(
        new Error('DefaultReject', {
          cause: 'Закрыли модалку авторизации не вводя номер',
        })
      );
    } catch (err) {
      LOG.event('Auth MobAuthService err ', err);
      throw err;
    }
  }

  async auth(ctx = {}) {
    let { refreshToken } = ClientService.getContext();
    LOG.event('AuthService.authorize start choco app', {
      ...ctx,
      refreshToken,
    });

    if (DevConfigs.isDev && !DevConfigs.login) {
      LOG.event('Auth reject isDev', ctx);
      return this.authReject();
    }

    if (this.isMiniAppWithCheckAuth()) {
      if (ctx.isInit || ctx.isRefresh) {
        const isAuthorizedByAppProvider = nativeBridge.checkAuth();
        LOG.event('Auth isMiniAppWithCheckAuth check', {
          ctx,
          'nativeBridge.checkAuth()': isAuthorizedByAppProvider,
        });
        return isAuthorizedByAppProvider &&
          isAuthorizedByAppProvider !== 'false'
          ? this.authInMiniApp()
          : this.authReject();
      } else {
        LOG.event('Auth isMiniAppWithCheckAuth immediate', ctx);
        return this.authInMiniApp();
      }
    }

    if (this.shouldFetchTokens(ctx, refreshToken)) {
      return this.fetchTokens(ctx);
    }

    if (ctx.isInit) {
      LOG.event('Auth reject isInit', { ...ctx });
      return this.authReject();
    }

    return this.authInMiniApp();
  }

  async authorize(ctx) {
    try {
      await this.auth(ctx);
      this.handleAuthSuccess();
    } catch (err) {
      LOG.event('Auth authorize reject', { err });
      return this.handleAuthError(err);
    }
  }
}
